import './actiontext.scss';
import './trix.css';
import './easy-autocomplete.css';
import './jquery.Jcrop.min.css';


import './bootstrap.css';
import 'tippy.js/dist/tippy.css'; 
import './themeLight.css';
import './themeDark.css';
import './daterangepicker.css';

import './style.css';
import './tablet-big.css';
import './tablet-small.css';
import './mobile.css';

import './new-themeLight.css';
import './new-themeDark.css';
import './new-style.css';
import './new-tablet-big.css';
import './new-tablet-small.css';
import './new-mobile.css';
import './new-23-03-23.css';
import './new-26.05.23.css';
import './new-02.10.23.css';

import './player.css';
import './daterangepicker.custom.css';
import './new-player.css';


import './swiper.min.css';
import './magnific-popup.css';
import './select-pure.css'; 

import './cookies_info.scss';

import './stripe_elements.scss';
import './stripe_button.css';

import './socia-share-buttons.css';

import './multi-select.css';

import './comments.scss';
import './custom.scss';
import './custom_LightDark.scss';
import './custom_tablet_small.scss';
import './custom_mobile.scss';


 
