$(document).ready(function () {
	initPlayerNew();
});

var initPlayerNew = function () {

	$('.ic-player-item-repeat').each(function () {
		var link = $(this);
		var menu = link.next('.ic-player-repeat-wrap');
		var clone, links, fader;
		var wrapper = link.parent().parent('.ic-player-playlist-scroll');

		var _closeMenu = function () {
			link.removeClass('open');
			clone.removeClass('open');
			setTimeout(function () {
				clone.remove();
			}, 200);
		};

		var _openMenu = function () {
			link.addClass('open');
			clone = menu.clone();
			setTimeout(function () {
				clone.addClass('open');
			}, 10);
			clone.appendTo('body');
			clone.css({
				'top': link.offset().top,
				'left': link.offset().left,
			});

			clone.hover(function () {
				clone.addClass('hovering');
			}, function () {
				clone.removeClass('hovering');
			});

			links = clone.find('.small-repeat-selector .ic-player-repeat-link,.small-repeat-selector .ic-player-repeat-ok');
			fader = clone.find('.ic-player-repeat-fader');
	

			links.on('click', function () {
				 
				 parent = $(this).parents(".small-repeat-selector");
				 
				 if($(this)[0].dataset.option == "custom"){
			       var count = parseInt( $(this).parent(".ic-player-repeat-number").children("input")[0].value);   
			       if (isNaN(count)){ 
			        count = 0;
		           };
				 }else{
				   var count = $(this)[0].dataset.option;
				 };
				 
		         $.ajax({
			       url: "/change_ics_repeat?id=" + parent[0].dataset.comid + "&ic_id=" + parent[0].dataset.icid + "&ic_repeats=" + count,
			       type: "POST"
		  	     })
		  	     
		  	     var number = $("#ic-repeat-select"+parent[0].dataset.icid + " .num");
		  	     var icItem =$("#ic-item"+parent[0].dataset.icid);
		  	     if (count > 0){
		  	       $("#ic-repeat-select"+parent[0].dataset.icid).addClass("repeat-show")
		  	       number.show();
		  	       number[0].innerHTML = count;
		  	       icItem.attr("data-repeat", count);
		  	       icItem.attr("data-repeatleft", count);
		  	     }else{
		  	       $("#ic-repeat-select"+parent[0].dataset.icid).removeClass("repeat-show");
		  	       number.hide();
		  	       number[0].innerHTML = "";
		  	       icItem.attr("data-repeat", 0);
		  	       icItem.attr("data-repeatleft", 0);
		  	     }
		  	     
				_closeMenu();
			});

			fader.on('click', function () {
				_closeMenu();
			});
            

		};

		link.on('click', function () {
			if (link.hasClass('open')) {
				_closeMenu();
			} else {
				_openMenu();
			}
		});

		wrapper.on('scroll', _closeMenu);

		link.hover(function () {
			link.addClass('hovering');
		}, function () {
			link.removeClass('hovering');
		});

		$('body').click(function () {
			if (!link.hasClass('hovering') && clone != undefined && !clone.hasClass('hovering')) {
				_closeMenu();
			}
		});
		$(document).keyup(function (e) {
			if (e.keyCode === 27) {
				_closeMenu();
			}
		});
	});
	
	
 	var okMainSelect = $(".main-repeat-selector .ic-player-repeat-ok"); 
 	okMainSelect.on("click",function(){
 	  $(".ic-player-button-wrap.ic-player-button-repeat").removeClass("open");	
 	});
	
};
