import $ from 'jquery';
import tippy from 'tippy.js';
import Trix from 'trix';

$(document).ready(function () {
  initInputClear();
  initMenuActions();
  initPost();
  initSmileys();
  initEditable();
  initNotifications();
  initEvents();
  initChat();
  initLinkCopy();
  initNotifs();
  initMentions();
//	openMenu();
});

var initInputClear = function () {
  $('.input-clear').on('click', function () {
    $(this).parent().find('input').val('').focus();
  });
};

var initLinkCopy = function () {
  $('.actions-menu-link-copy').on('click', function () {
    $('#community-notification-copied').addClass('open');
    setTimeout(function () {
      $('#community-notification-copied').removeClass('open');
    }, 1000);
  });
}

var initNotifications = function () {
  $('.community-notification').each(function () {
    var hold = $(this);
    hold.find('.community-notification-close').on('click', function () {
      hold.removeClass('open');
    });
  });
};

var initEditable = function () {
  $("[contenteditable]").focusout(function () {
    var element = $(this);
    if (!element.text().trim().length) {
      element.empty();
    }
  });
};

var initMenuActions = function () {
  [".menu-actions", ".editor-privacy", ".chat-inner-actions", ".chat-message-actions", ".profile-card-actions"].forEach(function(item,index){
    var hold = item;
    var link = hold + ' .toggle'; 
    var fader = hold + ' .fader';
    var links = hold + ' .actions-menu-link';
    var head = hold + ' .checks-menu-title';
    
    $(document).on("click", link, function() {
      $(this).parents(hold).toggleClass('open');
    })

    $(document).on("click", fader, function() {
      $(this).parents(hold).removeClass('open');
    })

    $(document).on("click", links, function() {
      $(this).parents(hold).removeClass('open');
    });

    $(document).on("click", head, function() {
      if ($(window).outerWidth() <= 640) {
        $(this).parents(hold).removeClass('open');
      }
    });

    $(hold).hover(function () {
      $(hold).addClass('hovering');
    }, function () {
      $(hold).removeClass('hovering');
    });

    $('body').click(function () {
      if (!$(hold).hasClass('hovering')) {
        $(hold).removeClass('open');
      }
    });
    
    $(document).keyup(function (e) {
      if (e.keyCode === 27) {
        // test hold.removeClass('open');
      }
    });
  });
  
  
  // $('.menu-actions, .editor-privacy, .chat-inner-actions, .chat-message-actions, .profile-card-actions').each(function () {
    
  
  // 	var hold = $(this);
  // 	var link = hold.find('.toggle');
  // 	var fader = hold.find('.fader');
  // 	var links = hold.find('.actions-menu-link');
  // 	var head = hold.find('.checks-menu-title');

  // 	link.on('click', function () {
  // 		hold.toggleClass('open');
  // 	});

  // 	fader.on('click', function () {
  // 		hold.removeClass('open');
  // 	});

  // 	links.on('click', function () {
  // 		hold.removeClass('open');
  // 	});

  // 	head.on('click', function () {
  // 		if ($(window).outerWidth() <= 640) {
  // 			hold.removeClass('open');
  // 		}
  // 	});

  // 	hold.hover(function () {
  // 		hold.addClass('hovering');
  // 	}, function () {
  // 		hold.removeClass('hovering');
  // 	});

  // 	$('body').click(function () {
  // 		if (!hold.hasClass('hovering')) {
  // 			hold.removeClass('open');
  // 		}
  // 	});
  // 	$(document).keyup(function (e) {
  // 		if (e.keyCode === 27) {
  // 			hold.removeClass('open');
  // 		}
  // 	});
  // });
};

var initPost = function () {
  $(document).on('click', '.post-stat-like', function () {
  // 26.05.23
    $(this).toggleClass('liked'); 
  });

  $('.link-add-post').on('click', function () {
    $('.post-form').addClass('open');
    $('html').addClass('open-post');
  });
  
  //attach file
///	$('input[type=file]').on('change', ({ target })=> {
  /*
    $(document).on('change', 'input[type=file]', function() {
      // var editorEl = $(this).parent().parent().find('trix-editor')[0];
      var reader = new FileReader();
      console.log($(this).parent());
      reader.addEventListener('load', ()=> {
        var image = document.createElement('img');
        image.src = reader.result;
        var tmp = document.createElement('div');
        tmp.appendChild(image);
       /// editorEl.editor.insertHTML(tmp.innerHTML);
       // editorEl.editor.insertHTML(".");
        //target.value = '';
      }, false);
      reader.readAsDataURL(this.files[0]);
    });
    */

   $(document).on("click", ".comment-actions-like", function() {
     var comm = $(this).parent().parent('.comment');

     if (comm.length == 0) {
       var comm = $(this).parent().parent('.post');
     }

     if (!comm.hasClass("cant-like")) {
       comm.addClass('has-likes');
       var likecount = parseInt(comm.find(".comment-like-count").first()[0].innerHTML);

       if (comm.hasClass('liked')) {
         comm.removeClass('liked');
         comm.find(".comment-like-count").first()[0].innerHTML = likecount - 1;
       } else {
         comm.addClass('liked');
         comm.find(".comment-like-count").first()[0].innerHTML = likecount + 1;
       }
     }
   })
   
    $(document).on("click", ".comment-toggle-replies", function(){
      var wrap = $(this).parent().parent('.comment-wrap');
      var replies = wrap.find('.comment-replies > .comment-wrap:not(.comment-wrap-form)');
      var delay = 300;
      
      if (!$(this).hasClass("replies-loaded")) {
        $(this).find(".fa-spinner").show();
        $(this).find("svg").hide();

        var comid = $(this)[0].dataset.comid;

        $.ajax({
          url: "/load_comment_replies?id="+comid,
          type: "GET",
          success: function(){
            $(`.reply-to-${comid}Comment`).hide();
            $(`.reply-to-${comid}Comment`).show(delay);
          },
        });
        $(this).addClass("replies-loaded");
      };

      wrap.toggleClass('open'); // Just toggle class for desktop and tablets

      if ($(this).hasClass("replies-loaded")){
        replies.toggle(delay);
      }
           
      // if ($(window).outerWidth() <= 640) {
      // 	// just for example start
      // //	comments.find('.comment-wrap').not(wrap).not(wrap.find('.comment-wrap')).addClass('hide'); // Hide other comments
      // //	wrap.addClass('reply open').appendTo(comments); // Move this comment to main place
      // //	hold.addClass('open-replies'); // To show .post-top-title-replies
      //     wrap.toggleClass('open'); // Just toggle class for desktop and tablets
      //     if ($(this).hasClass("replies-loaded")){
      //      	replies.toggle(300);
      //     }
      // 	// just for example end
      // } else {
      //      wrap.toggleClass('open'); // Just toggle class for desktop and tablets
      // 	replies.toggle(300);
      // }
    });
  
    // hold.find('.comment-actions-reply').on('click', function () {
    $(document).on("click", ".comment-actions-reply", function(){
      var hold = $(this).closest(".post:not(.post-form)");
      var wrap = $(this).parent().parent().parent('.comment-wrap');
      var comments = hold.find('.post-comments');

      $(".comment-wrap").removeClass('reply'); //remove all form fields so then only one will be open
      $(".comment-bg").removeClass("highlighting");
            
      if ($(window).outerWidth() <= 640) {
        var id = this.dataset.comid
        var name = this.dataset.username
        var model = this.dataset.model

        $(".comment-wrap-form").not("#reply-form-commentid0Comment, #reply-form-commentid0Testimonial").hide(); //hide all reply forms but not Main form to make new comment
        // just for example start
        //  comments.find('.comment-wrap').not(wrap).not(wrap.find('.comment-wrap')).addClass('hide'); // Hide other comments
        // wrap.addClass('reply open').appendTo(comments); // Move this comment to main place
        wrap.addClass('reply')//.appendTo(comments); // Move this comment to main place
        $("#reply-form-commentid"+id+model).appendTo(comments).show();
        // console.log("ID "+id);
        $("#reply-form-commentid"+id+model + " .user-name-reply")[0].innerHTML = name;
        $("#commnetid"+id + model+":not(.post) .comment-bg").first().addClass("highlighting");
        // $("#commnetid"+id + model+".post .comment-bg").first().addClass("highlighting");
        setTimeout(() => {
          $("#commnetid"+id+model)[0].scrollIntoView({behavior: "smooth", block: "center"  });
        },100)
   
        // 	hold.addClass('open-replies'); // To show .post-top-title-replies
        hold.addClass('open');
        // just for example end
        wrap.parent().find("trix-editor").last().focus();
      } else {
        wrap.addClass('reply'); // Just toggle class for desktop and tablets
        wrap.find("trix-editor").last().focus();
      }
      
      //add "mention at to the empy reply"
      var sgid = $(this)[0].dataset.sgid;
      var content = $(this)[0].dataset.content;
       
      if (sgid !==""){
        if ($(window).outerWidth() <= 640) {
          //console.log($(this).parent().parent().parent().parent().find(".mention-text-field").last());
          //console.log($(this).closest('.post-comments').find(".mention-text-field").last());
          var editor = $(this).closest('.post-comments').find(".mention-text-field").last()[0].editor;
        } else {
          var editor = $(this).parent().parent().parent().find(".mention-text-field").last()[0].editor;
        }

        var position = editor.getPosition()

        if (position == 0) { // only first time inserting
          let att = new Trix.Attachment({
            sgid: sgid,
            content: content
          })
          editor.insertAttachment(att);
          editor.insertString(" ");
        }
      }
    });
    // $('.post:not(.post-form)').each(function () {
  
  $(document).on("click", ".post:not(.post-form)", function() {
    var hold = $(this);

    hold.find('.actions-menu-link-save').on('click', function () {
      hold.find('.community-notification-saved').addClass('open');
      setTimeout(function () {
        hold.find('.community-notification-saved').removeClass('open');
      }, 3000);
    });

    var linkOpen = hold.find('.post-clickable-area');
    var linkClose = hold.find('.post-top-title-main');
    var linkCancel = $(".cancel-reply");

    // var comments = hold.find('.post-comments');

    var _open = function () {
      hold.addClass('open');
      $('html').addClass('open-post');
    };

    var _close = function () {
      hold.removeClass('open');
      $('html').removeClass('open-post');
    };

    linkOpen.on('click', _open);
    linkClose.on('click', _close);
    linkCancel.on('click', _close);

    //	$('.comment-actions-like').each(function () {
    //		var like = $(this);
    //	});
    linkClose.on('click', function() {
      $(".comment-wrap-form").not("#reply-form-commentid0Comment, #reply-form-commentid0Testimonial").hide();
      $(".comment-bg").removeClass("highlighting");
      $(".comment-wrap").removeClass('reply');
    });
      
    linkCancel.on('click', function() {
      $(".comment-wrap-form").not("#reply-form-commentid0Comment, #reply-form-commentid0Testimonial").hide();
      $(".comment-bg").removeClass("highlighting");
      $(".comment-wrap").removeClass('reply');
    });
    // hold.find('.comment-toggle-replies').on('click', function () {
  });

  $('.post-form').each(function () {
    var hold = $(this);
    var link = hold.find('.post-form-toggle:not(.login-to-continue)');
    var linkClose = hold.find('.post-top-title-main');
    var player = hold.find('lottie-player');

    var _open = function () {
      hold.addClass('open');
      $('html').addClass('open-post');
    };

    var _close = function () {
      hold.removeClass('open');
      $('html').removeClass('open-post');
    };

    link.on('click', _open);
    linkClose.on('click', _close);

    hold.find('.editor-send').on('click', function () {
      _postingStart();

      setTimeout(_postingEnd, 1000);
    });

    var _postingStart = function () {
      hold.addClass('posting');
      player.each(function () {
        this.play();
      });
    };

    var _postingEnd = function () {
      hold.removeClass('posting');
      _close();
      player.each(function () {
        this.pause();
      });
      $('#community-notification-posted').addClass('open');
      setTimeout(function () {
        $('#community-notification-posted').removeClass('open');
      }, 5000);
    };
  });
};

window.initSmileys = function () {
  $('.editor-add-smile:not(.activated-smiles, .submit-comment)').each(function() {
    // 	    bB(this);
    // 	});

    // 	$(document).on("click", '.editor-add-smile .smile-activator', function(){
    // 	     bB($(this).parent(".editor-add-smile")[0]);
    // 		 console.log($(this).parent(".editor-add-smile")[0])
    // 		 //console.log("ADASAA");
    // 	});
    // function bB(x) {
    // $(document).on("each", '.editor-add-smile', function() {
    $(this).addClass("activated-smiles");

    var button = this;
    var place = $(this).parent().parent().find('.comment-edit-area, .post-edit-area, .chat-edit-area, trix-editor');
    var editor = place[0].editor;
    var template = document.getElementById('popup-smileys');

    tippy(button, {
      content: template.innerHTML,
      allowHTML: true,
      theme: 'popup',
      arrow: false,
      trigger: 'click',
      interactive: true,
      multiple: true,
      placement: 'top-end',
      maxWidth: 290,
      onShown: function () {
        _smileys();
      },
    });

    var instance = button._tippy;

    var _smileys = function () {
      $('.popup-smileys:not(.activated)').each(function () {
        var hold = $(this);
        hold.addClass('activated');
        var sections = hold.find('.popup-smileys-section');
        var links = hold.find('.popup-smileys-nav-link');
        var smileys = hold.find('.popup-smileys-link');

        smileys.on('click', function () {
          var text = $(this).html();
          // place.val(place.val() + text);

          editor.insertString(text);
          // place.append(text);
          // instance.hide();
        });

        links.on('click', function () {
          var i = $(this).index();
          var dest = hold.find('.popup-smileys-section').eq(i);

          hold.animate({
            scrollTop: dest.position().top + 1
          }, {
            queue: false,
            duration: 500
          });
        });

        var _scroll = function () {
          sections.each(function () {
            var s = $(this);
            var i = s.index();
            var link = hold.find('.popup-smileys-nav-link').eq(i);

            if (s.position().top <= hold.scrollTop() && (s.position().top + s.outerHeight()) >= hold.scrollTop()) {
              link.addClass('active');
            } else {
              link.removeClass('active');
            }
          });
        };

        _scroll();
        hold.on('scroll', _scroll);
      });
    };
  });
};

var initEvents = function () {
  $('.notifications-popup').each(function () {
    var hold = $(this);
    var link = $('.head-link-notifications-events');
    var linkSet = hold.find('.notifications-head-settings-toggle');
    var textSet = hold.find('.checks-menu');
    var headSet = hold.find('.checks-menu-title');

    link.on('click', function () {
      $('html').toggleClass('open-events');
      $('html').removeClass('open-menu');
    });

    linkSet.on('click', function () {
      hold.toggleClass('open-settings');
    });

    headSet.on('click', function () {
      if ($(window).outerWidth() <= 640) {
        hold.removeClass('open-settings');
      }
    });

    hold.hover(function () {
      hold.addClass('hovering');
    }, function () {
      hold.removeClass('hovering');
    });
    link.hover(function () {
      link.addClass('hovering');
    }, function () {
      link.removeClass('hovering');
    });
    linkSet.hover(function () {
      linkSet.addClass('hovering');
    }, function () {
      linkSet.removeClass('hovering');
    });
    textSet.hover(function () {
      textSet.addClass('hovering');
    }, function () {
      textSet.removeClass('hovering');
    });

    $('body').click(function () {
      if (!textSet.hasClass('hovering') && !linkSet.hasClass('hovering')) {
        hold.removeClass('open-settings');
      }
    });

    $('body').click(function () {
      if (!hold.hasClass('hovering') && !link.hasClass('hovering')) {
        $('html').removeClass('open-events');
      }
    });

    $(document).keyup(function (e) {
      if (e.keyCode === 27) {
        hold.removeClass('open-settings');
      }
    });
  });
};

var initChat = function () {
  $('#send-message-btn').on('click', function(e){
    e.stopPropagation();
    $('html').addClass('open-chat');
    $('html').addClass('chat-minify');

    const el = e.target;
    el.disabled = true;
    const user_id  = el.dataset.toUserId;
    window.openFormSendMessageToUser(user_id);
  });
  // added 02.10.23 start


  //Blocked users start
  $('.js_show-blocked').on('click', function () {
    $('.chat-popup').removeClass('open-settings');
    $('.chat-popup').addClass('show-blocked');
  });

  $('.chat-blocked-users-title').find('.back').on('click', function () {
    $('.chat-popup').removeClass('show-blocked');
  });

  //Blocked users end

  //Chat modals & "Add people" block start
  var _initChatModal = function () {
    $('.js_chatModal').magnificPopup({
      type: 'inline',
      closeBtnInside: true,
      mainClass: 'mfp-chat-modal',
      removalDelay: 200,
      closeMarkup: '<button title="%title%" type="button" class="mfp-close"><svg width="24" height="24"><use xlink:href="img/community.svg#close-circle"></use></svg></button>',
      callbacks: {
        open: function () {
          $('.mfp-close, .js_close_modal').on('click', function () {
            $.magnificPopup.close();
          });
        }
      }
    });
  };

  window._initChatModal = _initChatModal;

  $('.js_chat-report-done').on('click', function () {
    $.magnificPopup.close();

    setTimeout(function () {
      $.magnificPopup.open({
        items: {
          src: '#chat-report-done'
        },
        type: 'inline',
        closeBtnInside: true,
        mainClass: 'mfp-chat-modal',
        removalDelay: 200,
        closeMarkup: '<button title="%title%" type="button" class="mfp-close"><svg width="24" height="24"><use xlink:href="img/community.svg#close-circle"></use></svg></button>',
        callbacks: {
          open: function () {
            $('.mfp-close, .js_close_modal').on('click', function () {
              $.magnificPopup.close();
            });
          }
        }
      });
    }, 210);
  });

  $('.js_chat-block-done').on('click', function () {
    $.magnificPopup.close();

    setTimeout(function () {
      $.magnificPopup.open({
        items: {
          src: '#chat-block-done'
        },
        type: 'inline',
        closeBtnInside: true,
        mainClass: 'mfp-chat-modal',
        removalDelay: 200,
        closeMarkup: '<button title="%title%" type="button" class="mfp-close"><svg width="24" height="24"><use xlink:href="img/community.svg#close-circle"></use></svg></button>',
        callbacks: {
          open: function () {
            $('.mfp-close, .js_close_modal').on('click', function () {
              $.magnificPopup.close();
            });
          }
        }
      });
    }, 210);
  });

  var _showPeopleModal = function () {
    $.magnificPopup.open({
      items: {
        src: '#chat-add-people'
      },
      type: 'inline',
      closeBtnInside: true,
      mainClass: 'mfp-chat-modal',
      removalDelay: 200,
      closeMarkup: '<button title="%title%" type="button" class="mfp-close"><svg width="24" height="24"><use xlink:href="img/community.svg#close-circle"></use></svg></button>',
      callbacks: {
        open: function () {
          $('.mfp-close, .js_close_modal').on('click', function () {
            $.magnificPopup.close();
          });
        }
      }
    });
  };

  $('.js_add-people').on('click', function () {
    $('.chat-popup').addClass('show-add-people');
    if ($(window).outerWidth() <= 640) {
      _showPeopleModal();
    }
  });


  $('.chat-add-people > .btn').on('click', function () {
    $.magnificPopup.close();
  });

  var _initAddPeople = function(){
    $('.chat-add-people-search-wrap').each(function () {
      var par = $(this);
      var hold = par.find('.chat-add-people-list');
      var inp = par.find('.search-input > input');

      var _calc = function () {
        if (inp.outerWidth() < 270) {
          hold.addClass('to-right');
        } else {
          hold.removeClass('to-right');
        }

        hold.css({
          '--top': inp.offset().top + inp.outerHeight() + 'px',
          '--left': inp.offset().left + 'px',
          '--right': $(window).outerWidth() - inp.offset().left - inp.outerWidth() + 'px'
        })
      };

      _calc();
      $(window).on('resize', _calc);
      $('.chat-add-people').on('scroll resize', function () {
        _calc();
        _hide();
      });

      var _show = function () {
        if ($.magnificPopup.instance.isOpen) {
          // Actions for modal version of search
        } else {
          hold.addClass('fixed');
          // hold.appendTo('body');
          setTimeout(function () {
            hold.addClass('open');
          }, 10);
        }
      };
      var _hide = function () {
        if ($.magnificPopup.instance.isOpen) {
          // Actions for modal version of search
        } else {
          hold.removeClass('open');
          setTimeout(function () {
            hold.appendTo(par);
            hold.removeClass('fixed');
          }, 200);
        }
      };

      inp.on('change keyup focus', function () {
        _calc();
        if (inp.val() === '') {
          _hide();
        } else {
          _show();
        }
      });

      inp.on('blur', function () {
        _hide();
      });

    });
  };
  window._initAddPeople = _initAddPeople;
  //Chat modals & "Add people" block end

  // Mute icon in chat header start
  $('.chat-main-mute').on('click', function () {
    $(this).toggleClass('active');
  });
  // Mute icon in chat header end
  var _initChatModalDetailsUserActions = function(){
    // Opening action menu inside "Details" modal start
    $('.chat-modal-details-user-actions').each(function () {
      var par = $(this);
      var hold = par.find('.chat-modal-details-user-actions-popup');
      var link = par.find('.toggle');
      var actions = par.find('.actions-menu-link');


      var _calc = function () {
        hold.css({
          '--top': link.offset().top + 'px',
          '--right': $(window).outerWidth() - link.offset().left - link.outerWidth() + 'px',
        })
      };

      _calc();
      $(window).on('resize', _calc);

      var _show = function () {
        _calc();
        par.addClass('open');
        hold.addClass('fixed');
        hold.appendTo('body');
        setTimeout(function () {
          hold.addClass('open');
        }, 10);
      };
      var _hide = function () {
        par.removeClass('open');
        hold.removeClass('open');
        setTimeout(function () {
          hold.appendTo(par);
          hold.removeClass('fixed');
        }, 200);
      };

      link.on('click', function () {
        _show();
      });

      actions.on('click', function () {
        _hide();
      });

      hold.hover(function () {
        hold.addClass('hovering');
      }, function () {
        hold.removeClass('hovering');
      });

      link.hover(function () {
        link.addClass('hovering');
      }, function () {
        link.removeClass('hovering');
      });

      $('body').click(function () {
        if (!hold.hasClass('hovering') && !link.hasClass('hovering')) {
          _hide();
        }
      });
      $(document).keyup(function (e) {
        if (e.keyCode === 27) {
          _hide();
        }
      });

      $('.chat-modal-details, .chat-modal-details-list-inner').on('scroll', _hide);

    });
    // Opening action menu inside "Details" modal end
  }
  window._initChatModalDetailsUserActions = _initChatModalDetailsUserActions;

  // added 02.10.23 end


  $('.chat-popup').each(function () {
    var hold = $(this);
    var linkHead = $('.head-link-notifications-chat');
    var linkFoot = $('.chat-bottom-link');
    var linkMinify = hold.find('.chat-zoom');
    var linkClose = hold.find('.chat-close');
    var linkSet = hold.find('.chat-main-setting-toggle');
    var textSet = $('.chat-popup-settings');
    var linkNew = hold.find('.chat-main-new-toggle'); // added 02.10.23
    var textNew = $('.chat-popup-new > .popup'); // added 02.10.23
    var linkShowAdding = $('.chat-center-message-add-people'); // added 02.10.23
    var linkHideAdding = $('.chat-add-people-close'); // added 02.10.23
    var headSet = textSet.find('.checks-menu-title');
    var linkOpenInner = hold.find('.chat-main-el');
    var linkCloseInner = hold.find('.chat-inner-head').find('.back');
    var newChatLinks = $('.chat-popup-new').find('.actions-menu').find('.actions-menu-link');

    newChatLinks.on('click', function () {
      hold.removeClass('open-new');
    })

    // added 02.10.23 start
    linkShowAdding.on('click', function () {
      hold.addClass('show-add-people');
    });

    linkHideAdding.on('click', function () {
      hold.removeClass('show-add-people');
    });
    // added 02.10.23 end


    hold.find('.chat-inner').find('.scroll-inner').on('scroll', function () {
      $(this).find('.chat-message-actions').removeClass('open');
    });

    hold.hover(function () {
      hold.addClass('hovering');
    }, function () {
      hold.removeClass('hovering');
    });
    linkHead.hover(function () {
      linkHead.addClass('hovering');
    }, function () {
      linkHead.removeClass('hovering');
    });
    linkFoot.hover(function () {
      linkFoot.addClass('hovering');
    }, function () {
      linkFoot.removeClass('hovering');
    });
    linkSet.hover(function () {
      linkSet.addClass('hovering');
    }, function () {
      linkSet.removeClass('hovering');
    });
    textSet.hover(function () {
      textSet.addClass('hovering');
    }, function () {
      textSet.removeClass('hovering');
    });


    // added 02.10.23 start
    linkNew.hover(function () {
      linkNew.addClass('hovering');
    }, function () {
      linkNew.removeClass('hovering');
    });
    textNew.hover(function () {
      textNew.addClass('hovering');
    }, function () {
      textNew.removeClass('hovering');
    });

    linkNew.on('click', function () {
      hold.addClass('open-new');
    });
    // added 02.10.23 end


    linkSet.on('click', function () {
      hold.addClass('open-settings');
    });

    headSet.on('click', function () {
      if ($(window).outerWidth() <= 640) {
        hold.removeClass('open-settings');
      }
    });

    linkHead.on('click', function () {
      $('html').toggleClass('open-chat');
      $('html').addClass('chat-minify');
      hold.removeClass('show-inner');
    });

    linkFoot.on('click', function () {
      $('html').toggleClass('open-chat');
      $('html').addClass('chat-minify');
      hold.removeClass('show-inner');
    });

    linkMinify.on('click', function () {
      window.vm.focusCursorOnMessage()
      $('html').toggleClass('chat-minify');
    });

    linkClose.on('click', function () {
      window.vm.saveDraftMessage()
      $('html').removeClass('open-chat');
    });

    $('body').click(function () {
      if (!textSet.hasClass('hovering') && !linkSet.hasClass('hovering')) {
        hold.removeClass('open-settings');
      }

      // edited 02.10.23 start
      if (!textNew.hasClass('hovering') && !linkNew.hasClass('hovering')) {
        hold.removeClass('open-new');
      }

      // if (!hold.hasClass('hovering') && !linkHead.hasClass('hovering') && !linkFoot.hasClass('hovering') && !textSet.hasClass('hovering') && $('html').hasClass('chat-minify')) {
      //   $('html').removeClass('open-chat');
      // }
      // edited 02.10.23 end
    });


    $(document).keyup(function (e) {
      if (e.keyCode === 27) {
        hold.removeClass('open-settings');
        hold.removeClass('open-new'); // added 02.10.23
      }
    });
  });
};

var initNotifs = function () {
  $(".head-link-notifications-events").on("click", function() {
    if(!$(this).hasClass("notifications-loaded")){
      $.ajax({
        url: "/load_notifications",
        type: "GET",
      });
    }
    $(this).addClass("notifications-loaded");
  });

  $(".read-all-notifications").on("click", function() {
    if(!$(this).hasClass("disabled")){
     $.ajax({
       url: "/read_all_notifications",
       type: "GET",
     });
    }
    $(this).addClass("disabled");
    $(".head-link-notifications-events").removeClass("has-new");
    $(".notifications-el").removeClass("new");
     
  });
}

var initMentions = function () {
  var requestTimer;

  if ($(".mention-text-field").length > 0) {
    var actKey = 47;
    var actSymbol = "@"; // for users
    var actSymbol2 = "/"; //for ics & complexes
    var search = false;
    var keyword, model, userMention, icMention, keySymbol;

    $("body").on("click", function () {
      $(".mention-menu").hide();
    })

    $(document).on("keyup", ".mention-text-field", function () {
      var val = $(this).find("div").html();

      if (val) {
        userMention = val.lastIndexOf(actSymbol) >= 0 && [">", ";", " "].includes(val.charAt(val.lastIndexOf(actSymbol) - 1));
        icMention = val.lastIndexOf(actSymbol2) >= 0 && [">", ";", " "].includes(val.charAt(val.lastIndexOf(actSymbol2) - 1));

        if (userMention || icMention) {
          if (userMention) {
            keySymbol = actSymbol;
            model = "user";
            $(".mention-menu-users").show();
            $(".mention-menu-ics").hide();
          } else {
            keySymbol = actSymbol2;
            model = "ic";
            $(".mention-menu-users").hide();
            $(".mention-menu-ics").show();
          }

          keyword = val.slice(val.lastIndexOf(keySymbol) + 1)

          var commentId = this.dataset.comid;
          var objClass = this.dataset.objclass;

          if (!(keyword.indexOf("&nbsp;") >= 0)) {
            const target_menu = $(this).parent().find(".mention-menu") //.show();
            const inChat = target_menu.prevObject[0].classList[0] === 'chat-form-inner'
            target_menu.show();

            if (requestTimer) {
              window.clearTimeout(requestTimer)
            } //see if there is a timeout that is active, if there is remove it.

            if (keyword.length > 0) {
              requestTimer = setTimeout(function () {
                searchMention(keyword, commentId, model, objClass, inChat)
              }, 400);  //delay before making the call
            }
          } else {
            $(".mention-menu").hide();
          }
        }
      } else {
        $(".mention-menu").hide();
      }
    })
  }

  function searchMention(keyword, commentId, model, objClass, inChat) {
    if (inChat) {
      $.ajax({
        'url': '/chat/mentions?keyword=' + keyword + "&comid=" + commentId + "&model=" + model + "&objclass=" + objClass,
        'type': 'GET'
      })
    } else {
      $.ajax({
        'url': '/user_mentions?keyword=' + keyword + "&comid=" + commentId + "&model=" + model + "&objclass=" + objClass,
        'type': 'GET'
      })
    }
  }

  $(document).on("click", ".mention-menu-link", function () {
    const tmp_editor = $(this).parent().parent().parent().parent().find(".mention-text-field")[0];
    if (tmp_editor) {
      var editor = tmp_editor.editor;
    } else {
      let tmp_chat = document.getElementsByClassName('chat-form')[0];
      var editor = tmp_chat.getElementsByTagName('trix-editor')[0].editor
    }
    var position = editor.getPosition()
    editor.setSelectedRange([position - (keyword.length + 1), position])
    editor.deleteInDirection("backward")
    let attachment = new Trix.Attachment({
      sgid: this.dataset.sgid,
      content: this.dataset.content
    })
    editor.insertAttachment(attachment);
    editor.insertString(" ");
    $(".mention-menu").hide();
  })

  //for click attachment
  //$(document).on("click", ".editor-add-photo", function() {
  //	console.log("attach");
  //	// $(this).parent().find(".trix-button--icon-attach").first().click();
  //});

  //$(document).on("click",".trix-content a", function(e) {
  //      $(this).attr("target","_blank");
  // });

  $(".comment-wrap .trix-content a").attr("target", "_blank");

  // For those content inject by javascript, use an event handler for your trix container may be better.

  $(".editor-mention-link").on("click", function () {
    const tmp_editor = $(this).parent().parent().parent().parent().find(".mention-text-field")[0];
    if (tmp_editor) {
      var editor = tmp_editor.editor;
    } else {
      let tmp_chat = document.getElementsByClassName('chat-form')[0];
      var editor = tmp_chat.getElementsByTagName('trix-editor')[0].editor
    }
    var symbol = this.dataset.symbol;
    var menu = $(this).parent().parent().find(".mention-menu");
    editor.insertString(" ");
    editor.insertString(symbol);
    if (symbol === "@") {
      $(".mention-menu-users").show();
      $(".mention-menu-ics").hide();
    } else if (symbol === "/") {
      $(".mention-menu-users").hide();
      $(".mention-menu-ics").show();
    }

    keyword = ""; //to avoid error if user do not continue typing and clicks on already show options
    setTimeout(function () {
      menu.show();
    }, 100)
  })
}


window.addEventListener("trix-file-accept", function(event) {
   const acceptedTypes = [
    'image/jpeg', 
    'image/png', 
    'image/jpg', 
    'image/gif', 
    'application/pdf',
    'application/msword', // For .doc files
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // For .docx files
    'application/vnd.ms-excel', // For .xls files
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // For .xlsx files
  ];


  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Only support attachment of jpeg, jpg or png, pdf, excel and doc files");
  }
})


// document.addEventListener('trix-initialize', function(e) {
//   var bar = $(".comment-form-actions.trix-add-photo").last().parent().find("trix-toolbar");
//   bar.append($(".comment-form-actions.trix-add-photo").last());
// })


