import $ from 'jquery';
import magnificPopup from "magnific-popup";

$(document).ready(function(){ 

 window.openFaqQuCustom = function() {
	$('.faq-qu-ship').each(function () {
		var hold = $(this);
		var link = hold.find('h3, .h3');
		var text = hold.find('.in');

		link.on('click', function () {
			text.slideToggle(200, function () {
				hold.toggleClass('open');
			});
		});
	});
}



 
 if($("#shipping-rates-modal-btn").length > 0){
     
      // order shipments
     
   $(document).on("click", ".create_shipment_btn", function(){
     var id = $(this)[0].dataset.id;
     var box = $("#boxes"+ id).val();
     var shipper = $("#shipper"+ id).val();
    //  console.log("/create_ep_shipment_path?id="+$(this)[0].dataset.id+"&box="+box);
     if (box !=""){
         $(this).find(".fa-shipping-fast").addClass("fa-spin");
         
         $.ajax({
           url: "/create_ep_shipment?id="+id+"&box="+box+"&shipper="+shipper,
           type: "GET"
         });
     }else{
         $("#boxes"+ id).focus();
     };
   });
   
   $(document).on("click", ".remind_order_btn", function(){
    
      var id = $(this)[0].dataset.id;
      var loc = $("#loc"+ id).val();
      var code = $("#code"+ id).val();
      //  console.log("/create_ep_shipment_path?id="+$(this)[0].dataset.id+"&box="+box);
      if (loc !=""){
        if (window.confirm("Remind about unfinished order?")) {
         $(this).find(".fas fa-bell").addClass("fa-spin");
         $.ajax({
           url: "/order_remind?id="+id+"&loc="+loc+"&code="+code,
           type: "GET"
         });
        };
     }else{
         $("#loc"+ id).focus();
     };
     
   
   });
   

  
   //select rate     
   $(document).on("click", ".select-shipment-rate", function(){
     
     var order = $(this)[0].dataset.order;
     var rate = $(this)[0].dataset.rate;
    //  console.log("/create_ep_shipment_path?id="+$(this)[0].dataset.id+"&box="+box);
    $(".select-shipment-rate").removeClass("active-option");
    $(this).addClass("active-option");
     $.ajax({
       url: "/select_ep_rate?order="+order+"&rate="+rate,
       type: "GET"
     });
   });
   
   
   //mark sipping label printed    
   $(document).on("click", ".print-shipping-label", function(){
     var id = $(this)[0].dataset.id;
     $("#file-title"+id).show();
     $("#file-title"+id).select();
     document.execCommand("copy");
     $("#file-title"+id).hide();
     $.ajax({
       url: "/mark_label_printed?id="+id,
       type: "GET"
     });
   });
   
   
 };
 
 

});