

import consumer from "./consumer"


$(function() {
  $('[data-channel-subscribe="pdf_catalog"]').each(function(index, element) {
      
    console.log("catalog-channel js loaded");
    
    var $element = $(element),
        user_id = $element.data('user-id')
   //     messageTemplate = $('[data-role="message-template"]');

  //  $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000)        

    //App.cable.subscriptions.create(
    consumer.subscriptions.create(
      {
        channel: "PdfCatalogChannel",
        user: user_id
      },
      {
        received: function(data) {
         // var content = messageTemplate.children().clone(true, true);
         if (data.users_count){
         document.getElementById("streamming-users").innerHTML = data.users_count;
         }
         if (data.phase){
         document.getElementById("breath-phase").innerHTML = data.phase;
         };
         if (data.psi_value){
           document.getElementById("psi-value").innerHTML = data.psi_value;
         }
         
         if (data.pro_value){
           document.getElementById("pro-value").innerHTML = data.pro_value;
         }
         if (data.score_value){
           document.getElementById("score-value").innerHTML = data.score_value;
         }
         if (data.message){
           document.getElementById("update-catalog-btn").innerHTML = data.message;
         }
         if (data.message2){
           document.getElementById("update-autocomplete-btn").innerHTML = data.message2;
         }
  
   
         
      //    content.find('[data-role="message-text"]').text(data.body);
     //     $element.append(content);
        //   $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000);
        }
      }
    );
  });
});


