import NoSleep from '../nosleep.js';
import $ from 'jquery';

 
  //def no sleep
$(document).ready(function(){ 
    
  var noSleep = new NoSleep();
  var noSleepBtn = $("#start_button");
  
  if(noSleepBtn.length > 0){
       noSleepBtn.on('click', function enableNoSleep() {
           noSleepBtn.off('click', enableNoSleep, false);
           noSleep.enable();
           console.log("no-sloeep");
       });
  };
   
});