

import consumer from "./consumer"


$(function() {
  $('[data-channel-subscribe="translation_stats"]').each(function(index, element) {
      
    console.log("catalog-channel js loaded");
    
    var $element = $(element),
        user_id = $element.data('user-id')
   //     messageTemplate = $('[data-role="message-template"]');

  //  $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000)        

    //App.cable.subscriptions.create(
    consumer.subscriptions.create(
      {
        channel: "TrstatsChannel",
        user: user_id
      },
      {
        received: function(data) {
         // var content = messageTemplate.children().clone(true, true);

    
         if (data.total){
           document.getElementById("translations_total").innerHTML = data.total;
         }
         if (data.completed){
           document.getElementById("translations_complete").innerHTML = data.completed;
         }
         if (data.revision){
           document.getElementById("translations_revision").innerHTML = data.revision;
         }
         if (data.progress){
           document.getElementById("translations_progress").innerHTML = data.progress;
         }
         
  
   
         
      //    content.find('[data-role="message-text"]').text(data.body);
     //     $element.append(content);
        //   $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000);
        }
      }
    );
  });
});


