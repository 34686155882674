// import knob from "jquery-knob";
import $ from 'jquery';

$(document).ready(function () {
	initPlayer();
});

function clickTouch(event, el){
 if (event.type == "touchstart") { 
    el.off('click'); 
 } else if (event.type == "click") { 
    el.off('touchstart'); 
 };
 console.log(el);
};

function initPlayer() {
	$('.ic-player').each(function () {
		var hold = $(this);
		var playBtn = hold.find('.ic-player-start > .btn');
		var stopBtn = hold.find('.ic-player-button-stop > .ic-player-button');
		var pauseBtn = hold.find('.ic-player-button-play > .ic-player-button');
		var playlist = hold.find('.ic-player-playlist');
		var playlistContent = playlist.find('.ic-player-playlist-wrap');
		var playlistLinkMob = hold.find('.ic-player-toggle-playlist-link');
		var playlistCloseMob = hold.find('.ic-player-hide-playlist, .ic-player-playlist-fader');
		var repeatWrap = hold.find('.ic-player-button-repeat');
		var repeatPopup = repeatWrap.next('.ic-player-repeat-selector'); // edited 25.09.22
		var repeatFader = repeatWrap.next('.ic-player-repeat-fader'); // edited 25.09.22
		var repeatToggle = repeatWrap.find('.ic-player-button');
		var repeatLink = repeatPopup.find('.ic-player-repeat-link'); // edited 25.09.22
		var soundWrap = hold.find('.ic-player-sound');
		var soundToggle = soundWrap.find('.ic-player-sound-toggle');
		var soundValues = soundToggle.find('> *');
		var soundValue0 = soundToggle.find('.v0');
		var soundValue1 = soundToggle.find('.v1');
		var soundValue2 = soundToggle.find('.v2');
		var soundValue3 = soundToggle.find('.v3');
		var soundMin = soundWrap.find('.ic-player-sound-off');
		var soundInput = soundWrap.find('input');
		var val3 = soundInput.attr('max');
		var val0 = soundInput.attr('min');
		var freq = hold.find('.ic-player-freq.scrolling > .ic-player-freq-in');

		$('.ic-player-progress input').knob({
			'width': 68,
			'height': 68,
			'displayInput': false,
			'fgColor': "#017FDB",
			'bgColor': "#CFEBFF",
			'thickness': 0.1176,
			'z-index': 10
		});

		var _checkMobile = function () {
			if ($(window).width() <= 641) {
				hold.addClass('mobile');
			} else {
				hold.removeClass('mobile');
			}
		};

		var _playlistHeight = function () {
			playlist.css('height', playlistContent.outerHeight());
		};

		var _soundPic = function () {
			if (soundInput) {
				var val1 = Math.round((val3 - val0) / 3);
				var val2 = Math.round((val3 - val0) / 1.5);

				var val = soundInput.val();
				soundValues.removeClass('active');

				if (val === val0) {
					soundValue0.addClass('active');
				} else if (val <= val1) {
					soundValue1.addClass('active');
				} else if (val <= val2) {
					soundValue2.addClass('active');
				} else if (val <= val3) {
					soundValue3.addClass('active');
				}
			}
		};

		// playBtn.on('click', function () {
		// 	if (!$(this).hasClass('disabled')) {
		// 		// alert('Started!');
		// 		hold.addClass('processing');
		// 	}
		// });

		// stopBtn.on('click', function () {
		// 	// alert('Stopped!');
		// 	hold.removeClass('processing');
		// });

		// pauseBtn.on('click touchstart', function (e) {
		// 	clickTouch(e, $(this));
		// 	$(this).toggleClass('paused');
		// });

		_soundPic();
		soundInput.on('input', function () {
			_soundPic();
		});

		_checkMobile();
		$(window).on('resize', function () {
			_checkMobile();
		});

		soundMin.on('click touchstart', function (e) {
			clickTouch(e, $(this));
			soundInput.val(val0);
			_soundPic();
		});

		_playlistHeight();
		$(window).on('resize', function () {
			_playlistHeight();
		});

		playlistLinkMob.on('click touchstart', function (e) {
			clickTouch(e, $(this));
			playlist.addClass('open');
		});
		playlistCloseMob.on('click touchstart', function (e) {
			clickTouch(e, $(this));
			playlist.removeClass('open');
		});

		repeatToggle.on('click touchstart', function (e) {
			clickTouch(e, $(this));
			repeatWrap.toggleClass('open');
		});
		
		repeatFader.on('click', function () { // edited 25.09.22
			repeatWrap.removeClass('open');
		});

		repeatLink.on('click touchstart', function (e) {
			clickTouch(e, $(this));
			// alert('Option selected!');
			repeatWrap.removeClass('open');
		});

		soundToggle.on('click touchstart', function (e) {
			clickTouch(e, $(this));
			soundWrap.toggleClass('open');
		});


		repeatWrap.hover(function () {
			repeatWrap.addClass('hovering');
		}, function () {
			repeatWrap.removeClass('hovering');
		});
		
		repeatPopup.hover(function () { // edited 25.09.22
			repeatPopup.addClass('hovering');
		}, function () {
			repeatPopup.removeClass('hovering');
		});

		soundWrap.hover(function () {
			soundWrap.addClass('hovering');
		}, function () {
			soundWrap.removeClass('hovering');
		});

		$('body').click(function () {
			if (!repeatWrap.hasClass('hovering') && !repeatPopup.hasClass('hovering')) { // edited 25.09.22
				repeatWrap.removeClass('open');
			}
			if (!soundWrap.hasClass('hovering')) {
				soundWrap.removeClass('open');
			}
		});

		$(document).keyup(function (e) {
			if (e.keyCode === 27) {
				repeatWrap.removeClass('open');
				soundWrap.removeClass('open');
			}
		});

		freq.each(function () {
			var fr = $(this);
			var el = fr.find('.ic-player-freq-el');

			el.each(function () {
				var e = $(this);

				var _checkPos = function () {

					var t = e.position().top;

					if (t < (fr.scrollTop() + 1) || (t + e.outerHeight()) > (fr.scrollTop() + fr.parent().outerHeight())) {
						e.addClass('hideme');
					} else {
						e.removeClass('hideme');
					}
				};

				_checkPos();
				fr.on('scroll', function () {
					_checkPos();
				});
			});
		});

	});
}

