import $ from 'jquery';
 


$(document).ready(function(){ 
    
    if( $("#change-report-dates-btn").length > 0){
        
        $("#change-report-dates-btn").on('click', function(){
          $("#report-date-picker").show();
          $("#change-report-dates-btn").hide();  
          $("#report-results").hide();  
          
        });
    };
});