

import consumer from "./consumer"


$(function() {
  $('[data-channel-subscribe="icc"]').each(function(index, element) {
      
    console.log("icc-channel js loaded");
    
    var $element = $(element),
        icc_id = $element.data('icc-id')
   //     messageTemplate = $('[data-role="message-template"]');

  //  $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000)        

    //App.cable.subscriptions.create(
    consumer.subscriptions.create(
      {
        channel: "IccChannel",
        icc: icc_id
      },
      {
        received: function(data) {
         // var content = messageTemplate.children().clone(true, true);

    
         if (data.progress){
           console.log(data.progress)  ;   
        //   document.getElementById("update-autocomplete-btn").innerHTML = data.message2;
           $("#start_button").addClass("disabled");
           $("#start_button_no").addClass("disabled");
           $("#start-spinner").show();
           $("#start-icon").hide();
           $("#info").hide();
           var loading = $("#loading")
           loading.html(loading[0].dataset.generating+" "+data.progress +"%");
         }
         if (data.freq_data){
           console.log(data.progress)  ;   
           
           $("#start_button")[0].dataset.freq = data.freq_data
           $("#loading").html("");
           $("#info").show();
           $("#start_button").removeClass("disabled");
           $("#start_button_no").removeClass("disabled");
           $("#start-spinner").hide();
           $("#start-icon").show();
        //   document.getElementById("update-autocomplete-btn").innerHTML = data.message2;
         }
         
         
         // to request rate modal Testimonial new
          if (data.request_rate){
           $("#rate-ic-btn")[0].click();
         }
         
  
   
         
      //    content.find('[data-role="message-text"]').text(data.body);
     //     $element.append(content);
        //   $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000);
        }
      }
    );
  });
});


