

import consumer from "./consumer"


$(function() {
  $('[data-channel-subscribe="qb"]').each(function(index, element) {
      
    console.log("qb-channel js loaded");
    
    var $element = $(element),
        user_id = $element.data('user-id')
   //     messageTemplate = $('[data-role="message-template"]');

  //  $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000)        

    //App.cable.subscriptions.create(
    consumer.subscriptions.create(
      {
        channel: "QbChannel",
        user: user_id
      },
      {
        received: function(data) {
         // var content = messageTemplate.children().clone(true, true);
 
         if (data.done_count){
           document.getElementById("progress-qb").innerHTML = "Progress: " + data.done_count;
         }
 
  
   
         
      //    content.find('[data-role="message-text"]').text(data.body);
     //     $element.append(content);
        //   $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000);
        }
      }
    );
  });
});


